<script lang="ts" setup>
import type { BlokSection } from '~~/types/storyblok/bloks'
const props = defineProps<{ blok: BlokSection | undefined }>()

const blocks = computed(() => {
  return props.blok?.blocks || []
})

const sectionTheme = computed(() => {
  return props.blok?.theme ? props.blok.theme : 'theme-grey-100'
})

const sectionType = computed(() => {
  return props.blok?.type ? props.blok.type : 'section-basic'
})

const sectionSize = computed(() => {
  return props?.blok?.size === 'sm'
    ? 'px-18 md:px-36 xl:px-0 pt-48 pb-36 md:py-36 lg:py-[7.5vh]'
    : 'px-18 md:px-36 xl:px-0 py-48 md:py-72 lg:py-[12.5vh] xl:py-[17.5vh]'
})
</script>

<template lang="pug">
section(
  v-editable="blok"
  :class="[sectionTheme, sectionType, sectionSize]"
  class="blok-section text-base xl:text-md relative w-full"
  :id="props?.blok?.anchor_id"
)
  StoryblokComponent(
    v-for="blok in blocks"
    :key="blok._uid"
    :blok="{ ...blok }"
  )
</template>
